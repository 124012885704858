import { createStyles, makeStyles } from '@mui/styles';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  padding,
} from '@stewart/theme/index';

export const useFooterStyles = makeStyles(() =>
  createStyles({
    footer: {
      position: 'absolute',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${padding.zero} ${padding.xlarge1} ${padding.xlarge1}`,
      fontFamily: fontFamily.primary,
      width: '96vw',
      backgroundColor: 'rgb(229, 229, 229)',
      color: colors.grey27,
    },
    links: {
      marginTop: '20px',
      paddingBottom: '5px',
    },
    copyright: {
      fontFamily: fontFamily.primary,
      fontSize: fontSize.small,
      lineHeight: lineHeight.xsmall1,
      fontWeight: fontWeight.normal1,
      fontStyle: 'normal',
      marginTop: '20px',
    },
    link: {
      color: colors.grey27,
      fontSize: fontSize.small,
      fontWeight: fontWeight.normal1,
      paddingRight: padding.xlarge1,
    },
  })
);
