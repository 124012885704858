import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {
  borderRadius,
  boxShadows,
  colors,
  fontFamily,
  fontWeight,
  gaps,
  gradients,
  margin,
  padding,
} from '@stewart/theme/index';

export const useStewartDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowWrap: 'anywhere',
      padding: '12px !important',
      fontWeight: `700 !important`,
      fontSize: '14pt',
    },
    titleContainer: {
      height: '80px',
      margin: '-40px auto 0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: 'calc(100% - 26px)',
      fontSize: '24px',
      borderRadius: '8px !important',
      '& h2.MuiTypography-root': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    success: {
      background: gradients.successGreenGradient,
    },
    error: {
      background: gradients.errorGradient,
    },
    info: {
      background: gradients.blueBackgroundDarkGradient,
    },
    warning: {
      background: gradients.yellowWarningGradient,
    },
    dialogPaper: {
      borderRadius: '12px!important',
      maxWidth: '90vw !important', // 'none!important',
      overflowY: 'revert !important' as 'revert',
      boxShadow: boxShadows.boxShadowDialog,
      marginBottom: '0px !important',
    },
    staticDialogPaper: {
      height: 'auto !important',
      maxHeight: 'calc(100vh - 50px) !important',
      minHeight: '300px !important',
      marginTop: '60px !important',
    },
    closeIcon: {
      height: '50px',
      width: '50px',
      color: `${theme.palette.common.white}!important`,
      marginRight: '-20px!important',
    },
    action: {
      justifyContent: 'center!important',
      // padding: `${padding.xlarge1} !important`,
      padding: `${padding.medium1} ${padding.xlarge1} ${padding.xlarge1} ${padding.xlarge1} !important`,
    },
    customAction: {
      bottom: '5px !important',
      justifyContent: 'center !important',
      position: 'absolute !important' as any,
      width: '100% !important',
    },
    contentContainer: {
      fontFamily: `${fontFamily.primary} !important`,
      overflowY: 'auto !important' as any,
      // height: 'calc(100vh - 230px) !important',
    },
    contentContainerWithAction: {
      height: 'calc(100vh - 230px) !important',
    },
    manageCompanyAccessContentContainer: {
      fontFamily: `${fontFamily.primary} !important`,
      overflowY: 'auto !important' as any,
      height: 'calc(100vh - 210px) !important',
    },
    manageSignatureContentContainer: {
      fontFamily: `${fontFamily.primary} !important`,
      overflowY: 'auto !important' as any,
      height: 'calc(100vh - 220px) !important',
    },
    manageUsersCompanyAccessContentContainer: {
      fontFamily: `${fontFamily.primary} !important`,
      overflowY: 'auto !important' as any,
      height: 'calc(100vh - 210px) !important',
    },
    dualScrollbarContainer: {
      // maxHeight: 'calc(100vh - 260px) !important',
      maxHeight: 'calc(100vh - 220px) !important',
      maxWidth: '85vw !important',
      paddingBottom: '0px',
    },
    assignPermission_dualScrollbarContainer: {
      // maxHeight: 'calc(100vh - 260px) !important',
      maxHeight: 'calc(100vh - 220px) !important',
      maxWidth: '85vw !important',
      paddingBottom: '0px',
    },
    editUnderwriterConfiguration_dualScrollbarContainer: {
      maxHeight: 'calc(100vh - 220px) !important',
      maxWidth: '85vw !important',
      paddingBottom: '0px',
    },
    contentBlockStyle: {
      display: 'flex !important',
      flexDirection: 'row !important' as any,
      justifyContent: 'end !important',
      position: 'relative !important' as any,
      top: '8px !important',
    },
    wrapper: {
      position: 'relative !important' as any,
    },
    toggleSwitchActive: {
      // backgroundColor: colors.blue14,
    },
    icon: {
      fill: `${colors.blue14} !important`,
      width: '24px',
      height: '24px',
    },
    toastStyle: {
      position: `fixed !important` as any,
      bottom: '16px',
      right: '16px',
      maxWidth: '300px !important',
      borderRadius: borderRadius.large,
      color: 'white',
      minWidth: '450px',
    },
    toastLeftSuccess: {
      display: 'flex',
      alignItems: 'center',
      padding: padding.medium2,
      width: '90px',
      justifyContent: 'center',
      gap: gaps.small2,
      alignSelf: 'stretch',
      borderRadius: `${borderRadius.large} ${borderRadius.zero} ${borderRadius.zero}  ${borderRadius.large}`,
      background: `linear-gradient(180deg, ${colors.green} 0%, ${colors.green01} 100%)`,
    },
    toastLeftError: {
      display: 'flex',
      alignItems: 'center',
      padding: padding.medium2,
      width: '90px',
      justifyContent: 'center',
      gap: gaps.small2,
      alignSelf: 'stretch',
      borderRadius: `${borderRadius.large} ${borderRadius.zero} ${borderRadius.zero}  ${borderRadius.large}`,
      background: `${gradients.errorGradient}`,
    },
    toastMessage: {
      color: 'black',
      fontSize: '0.875rem',
      alignContent: 'left,',
      margin: margin.xlarge,
      fontWeight: fontWeight.bold1,
      lineHeight: '24.2px',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      backgroundColor: `${colors.green}`,
      borderRadius: `${borderRadius.large} ${borderRadius.zero} ${borderRadius.zero}  ${borderRadius.large}`,
      marginRight: margin.medium,
    },
    message: {
      flexGrow: 1,
      fontSize: '1rem',
      fontWeight: `${fontWeight.normal2}`,
    },

    closeButton: {
      color: 'white',
      padding: padding.xsmall1,
    },
    innerToastContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 0.9,
    },
  })
);
