import React from 'react';

const ErrorIconToast = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
      >
        <path
          d="M17 2.83325C9.16584 2.83325 2.83334 9.16575 2.83334 16.9999C2.83334 24.8341 9.16584 31.1666 17 31.1666C24.8342 31.1666 31.1667 24.8341 31.1667 16.9999C31.1667 9.16575 24.8342 2.83325 17 2.83325ZM24.0833 22.0858L22.0858 24.0833L17 18.9974L11.9142 24.0833L9.91668 22.0858L15.0025 16.9999L9.91668 11.9141L11.9142 9.91658L17 15.0024L22.0858 9.91658L24.0833 11.9141L18.9975 16.9999L24.0833 22.0858Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default ErrorIconToast;
