import { createStyles, makeStyles } from '@mui/styles';

import {
  borderRadius,
  colors,
  fontSize,
  fontWeight,
  gradients,
  margin,
  padding,
  boxShadows,
  mediaSize,
} from '@stewart/theme/index';

export const useMobileNavbarStyles = makeStyles(() =>
  createStyles({
    menuContainer: {
      top: '17px !important',
      maxWidth: '225px',
      '&:after': {
        border: 'solid transparent',
        borderBottomColor: colors.blue14,
        borderColor: 'transparent',
        borderWidth: '10px',
        bottom: '100%',
        content: `""`,
        height: '0',
        left: '50%',
        marginLeft: '-10px',
        pointerEvents: 'none',
        position: 'absolute',
        width: '0',
      },
      '& ul.MuiList-root': {
        background: gradients.blueBackgroundDarkGradient,
        borderRadius: borderRadius.small,
        padding: `${padding.large1} ${padding.xlarge1} !important`,
        boxShadow: boxShadows.boxShadow08,
      },
    },
    activeNavItem: {
      display: 'none',
      [`@media (max-width: ${mediaSize.minXlg}px)`]: {
        alignSelf: 'center !important',
        display: 'flex !important',
        marginLeft: 'auto !important',
      },
      '@media (max-width: 768px)': {
        marginRight: `${margin.zero} !important`,
      },
      '@media (min-width: 768px)': {
        marginRight: `${margin.large} !important`,
      },
      '& svg:first-child': {
        '@media (max-width: 767px)': {
          height: '11px',
          marginRight: '5px',
          width: '16px',
        },
      },
    },
    title: {
      fontWeight: `${fontWeight.bold1} !important`,
      '@media (max-width: 768px)': {
        fontSize: fontSize.xxsmall,
        margin: `${margin.zero}`,
      },
      '@media (min-width: 768px)': {
        fontSize: fontSize.medium,
        margin: `${margin.zero} ${margin.small2}`,
      },
    },
  })
);
