// Deploy
export const GET_TEST_PATH: string = 'users/test2';

// Validate Login
export const VALIDATE_LOGIN_API_PATH: string = 'authentication/processlogin';

// Lookup
export const GET_TIMEZONE_LIST_API_PATH: string = 'lookup/time-zones';
export const GET_LOGIN_TYPES_API_PATH: string = 'lookup/login-types';
export const GET_USER_TYPES_API_PATH: string = 'lookup/user-types';
export const GET_USER_STATUS_TYPES_API_PATH: string = 'lookup/user-status-types';
export const GET_COUNTIES_API_PATH: string = 'lookup/county?stateAbbr={stateAbbr}';

// Users
export const CURRENT_USERS_API_PATH: string = 'users';
export const USERS_USERNAMES_API_PATH: string = 'users/{userId}/stewart-access-usernames';
export const USERS_SELECTED_USERNAMES_API_PATH: string =
  'users/{userId}/stewart-access-usernames/{accountId}';
export const USERS_SETS_API_PATH: string = 'users/{userId}/permission-set-list';
export const USERS_UWA_USER_CONFIG_API_PATH: string = 'users/{userId}/uwauserconfig';
export const USERS_DEFAULT_SETS_API_PATH: string = 'permissions/default/{userTypeCode}';
export const USERS_SEARCH_API_PATH: string = 'users/search';
export const CURRENT_PROFILE_INFO_API_PATH: string = 'users/current';
export const CURRENT_USER_INVITATION_API_PATH: string = 'users/invitation';
export const CURRENT_BULK_INVITE_API_PATH: string = 'users/bulk-invite';
export const COUNT_USERS_API_PATH: string = 'users/count';
export const USERS_DOWNLOAD_API_PATH: string = 'users/download';
export const USER_METADATA_CACHE_API_PATH: string = 'users/{userId}/metadata-cache';
export const USER_PATCH_API_PATH: string = 'users/patch';
export const USER_CURRENT_PERMISSION_ITEM_LIST_API_PATH = 'users/current/permission-item-list';
export const USER_ACTIVATE_OR_DEACTIVATE_API_PATH: string = 'users/active';
export const USER_PERMISSION_CHANGE_LISTENER_API_PATH: string = 'hubs/userpermission';
export const USER_GET_COMPANIES_BY_USER_ID_API_PATH: string = 'users/{userId}/company-access-list';
export const USER_DELETE_COMPANY_FROM_USER_API_PATH: string = 'users/company-access/{identityKey}';
export const USER_BULK_DELETE_COMPANY_FROM_USER_API_PATH: string = 'users/company-access-list';
export const USER_GET_COMPANIES_BY_USER_ID_DOWNLOAD_API_PATH: string =
  'users/{userId}/company-access-list/download';
export const USER_DELETE_ALL_COMPANIES_FROM_USER_API_PATH: string =
  'users/{userId}/company-access-list-remove-all';
export const USER_DELETE_ALL_USERS_FROM_COMPANY_API_PATH: string =
  'company/RemoveAllUsersForCompany';
export const USER_GET_COUNTRY_LIST: string = 'users/get-country-list';
export const USER_GET_REGION_LIST: string = 'users/get-region-list';
export const USER_GET_DISTRICT_LIST: string = 'users/get-district-list';
export const USER_GET_STATE_LIST: string = 'users/get-state-list';
export const USER_GET_COMPANY_LIST: string = 'users/get-company-list';
export const USER_ASSIGN_OR_EDIT_COMPANY_FOR_USER: string = 'users/company-access';
export const USER_BULK_ASSIGN_COMPANIES_FOR_USER: string = 'users/company-access-bulk';
export const GET_USER_TYPE_LIST_API_PATH: string =
  'type-tables/search?typeTableName=UserType&showOnlyActive=true';

// Permissions
export const PERMISSIONS_API_PATH: string = 'permissions';
export const PERMISSIONS_GRID_API_PATH: string = 'permissions/search';
export const PERMISSIONS_USER_TYPE_API_PATH: string = 'permissions/user-type';
export const PERMISSIONS_USER_TYPE_DETAILS_API_PATH: string = 'permissions/user-type-details';
export const PERMISSIONS_USER_TYPE_SETS_API_PATH: string = 'permissions/user-type/sets';
export const PERMISSIONS_SET_DETAILS_API_PATH: string = 'permissions/details/{setCode}/search';
export const PERMISSIONS_ITEM_API_PATH: string = 'permissions/item';
export const PERMISSIONS_SET_API_PATH: string = 'permissions/set';
export const PERMISSIONS_ITEM_LIST_API_PATH: string = 'permissions/item-type-list';
export const PERMISSIONS_DOWNLOAD_API_PATH: string = 'permissions/download';
export const PERMISSIONS_VIEW_USERS_API_PATH: string = 'permissions/users';
export const PERMISSIONS_EXPORT_USERS_API_PATH: string =
  'permissions/users/{permissionSetTypeCode}/download';
export const PERMISSIONS_REMOVE_USERS_API_PATH: string = 'permissions/users-permission-delete';
export const PERMISSIONS_UPDATE_USERS_API_PATH: string =
  'permissions/users-permission-update/{setCode}';

// Metadata Cache
export const CACHE_API_PATH: string = 'metadata-cache';
export const CACHE_GRID_API_PATH: string = 'metadata-cache/search';
export const CLEAR_REDIS_CACHE_API_PATH: string = 'redis/flush-all';
export const CACHE_DOWNLOAD_API_PATH: string = 'metadata-cache/download';

// Configuration
export const GET_AUTH_CONFIGURATION_API_PATH: string = 'api/configuration/authentication';
export const AUTO_REPORTING_CONFIGURATION_API_PATH: string = 'configuration/get-config-data/search';
export const CONFIGURATION_TYPES_API_PATH: string = 'configuration/get-app-configs';
export const AUTO_REPORTING_CONFIGURATION_BASIS: string =
  'type-tables?typeTableName=AutoReportDateType';
export const AUTO_REPORTING_CONFIGURATION_FREQUENCY: string =
  'type-tables?typeTableName=AutoReportFrequencyType';
export const AUTO_REPORTING_CONFIGURATION_AGENCY = 'configuration/get-company-list-data';
export const AUTO_REPORTING_CONFIGURATION_STATE = 'configuration/get-state-list-by-companyid';
export const AUTO_REPORTING_CONFIGURATION_AGENCY_LOCATION =
  'configuration/get-location-list-by-companyid-state';
export const AUTO_REPORTING_CONFIGURATION_CREATE: string = 'configuration/create';
export const AUTO_REPORTING_CONFIGURATION_DOWNLOAD =
  'configuration/download?configTypeName={configtypename}';
export const AUTO_REPORTING_CONFIGURATION_DELETE = 'configuration/delete';

// Policy Upload
export const POLICY_UPLOAD_SEARCH_API_PATH: string =
  'policyupload/get-admin-documentsupplemental-data/search';
export const POLICY_UPLOAD_GET_DOCUMENT_SUPPLEMENTAL_URL_API_PATH: string =
  'policyupload/GetSupplementalDocumentUrl';
export const POLICY_UPLOAD_GET_BASE64_DOCUMENT_SUPPLEMENTAL_API_PATH: string =
  'policyimage/GetSupplementalDocument';
export const POLICY_UPLOAD_GET_PDF_BY_URL_API_PATH: string = 'policyupload/GetPdfByUrl';
export const POLICY_UPLOAD_DELETE_DOCUMENT_SUPPLEMENTAL_API_PATH: string =
  'policyupload/DeleteDocumentSupplemental';
export const POLICY_UPLOAD_GET_DOWNLOAD_ZIP_FILE_API_PATH: string =
  'policyimage/DownloadMultipleDocuments';
export const POLICY_UPLOAD_GET_DOWNLOAD_REUPLOAD_FILE_API_PATH: string =
  'policyupload/ReUploadPolicyImages';
export const POLICY_UPLOAD_SAVE_DOCUMENT_SUPPLEMENTAL_API_PATH: string =
  '/policyupload/SaveDocumentSupplemental';
export const POLICY_UPLOAD_EXPORT_API_PATH: string = 'policyimage/export';

// Logs{}
export const CLOUD_LOGS_API_PATH: string = 'cloud-logs';
export const CLOUD_LOGS_LINE_CHART_API_PATH: string = 'cloud-logs/line-chart';
export const GET_EXTERNAL_SYSTEM_REQUEST_API_PATH: string = 'db-logs/external-system-request-log';
export const GET_CHANGESET_REQUEST_API_PATH: string = 'db-logs/changeset-log';
export const GET_EXTERNAL_SYSTEM_REQUEST_GRID_API_PATH: string =
  'db-logs/external-system-request-log/search';
export const GET_CHANGESET_LOG_GRID_API_PATH: string = 'db-logs/changeset-log/search';
export const GET_CHANGESET_LOG_DOWNLOAD_API_PATH: string = 'db-logs/changeset-log/download';
export const EXTERNAL_SYSTEM_REQUEST_DOWNLOAD_API_PATH: string =
  'db-logs/external-system-request-log/download';
export const GET_INTERNAL_SYSTEM_REQUEST_API_PATH: string = 'db-logs/internal-system-request-log';
export const GET_INTERNAL_SYSTEM_REQUEST_GRID_API_PATH: string =
  'db-logs/internal-system-request-log/search';
export const GET_INTERNAL_SYSTEM_REQUEST_DOWNLOAD_API_PATH: string =
  'db-logs/internal-system-request-log/download';
export const GET_INTEGRATION_REQUEST_GRID_API_PATH: string =
  'db-logs/integration-system-request-log/search';
export const GET_INTEGRATION_REQUEST_API_PATH: string = 'db-logs/integration-system-request-log';
export const GET_INTEGRATION_SYSTEM_REQUEST_DOWNLOAD_API_PATH: string =
  'db-logs/integration-system-request-log/download';

// Types
export const TYPE_TABLES_API_PATH: string = 'type-tables';
export const TYPE_TABLES_AVAILABLE_API_PATH: string = 'type-tables/available';
export const TYPE_TABLES_DOWNLOAD_API_PATH: string = 'type-tables/download';

// Company Signatures
export const COMPANY_SIGNATURES_API_PATH: string = 'user-company-signatures';
export const COMPANY_SIGNATURES_CANCELLATION_LETTER_API_PATH: string =
  'user-company-signatures/cancellationletter?companyID=$companyID';
export const COMPANY_SIGNATURES_GRID_API_PATH: string = 'user-company-signatures/search';
export const COMPANY_SIGNATURES_SAMPLE_API_PATH: string = 'user-company-signatures/sample';
export const COMPANY_SIGNATURE_IMAGE_FORMATS_API_PATH: string = 'type-tables/search';

// Companies
export const COMPANIES_GRID_API_PATH: string = 'company/search';
export const COMPANIES_SIGNATURE_API_PATH: string = 'company/{companyID}/tps-signature/';
export const COMPANIES_STATES_API_PATH: string = 'company/{companyID}/tps-signature/states';
export const LEGACIES_STATES_API_PATH: string =
  'company/{companyID}/tps-signature/legacy-ids?stateId={stateId}';
export const LOCATIONS_STATES_API_PATH: string =
  'company/{companyID}/tps-signature/locations?stateId={stateId}&legacyId={legacyId}';
export const COMPANIES_DOWNLOAD_API_PATH: string = 'company/download';
export const COMPANIES_SIGNATURES_DOWNLOAD_API_PATH: string = 'user-company-signatures/download';
export const GET_USERS_FROM_COMPANY_API_PATH: string = 'company/UsersSearch';
export const USERS_FROM_COMPANY_DOWNLOAD_API_PATH: string = 'company/users/download ';
export const COMPANIES_GET_BRANCHES_OF_COMAPNY_API_PATH: string = 'company/branches/{companyID}';
export const COMPANIES_ADD_BRANCH_API_PATH: string = 'company/branches/';
export const COMPANIES_ADD_BRANCH_LOCATIONS_API_PATH: string = 'company/branchLocations';
export const COMPANIES_ADD_BRANCH_LOCATION_API_PATH: string = 'company/branchLocation';
export const COMPANIES_DELETE_BRANCH_OF_COMPANY_API_PATH: string = 'company/branches/{branchID}';
export const CONTRACTS_FOR_COMPANY_GRID_API_PATH: string = 'company/contracts/search';
export const TERRITORIES_FOR_CONTRACT_GRID_API_PATH: string =
  'company/contracts/territories/search';
export const LOCATIONS_FOR_TERRITORY_GRID_API_PATH: string = 'company/contracts/locations/search';

// File Management
export const GET_FILE_MGMT_TRANSMITTAL_ERRORS_API_PATH: string =
  'filemanagement/transmittalerrors/search';
export const TRANSMITTAL_ERRORS_DOWNLOAD_API_PATH: string =
  'filemanagement/transmittalerrors/download';
// ENDOZENDOUNMATCHED
export const ENDOZENDOUNMATCHED_FIX_API_PATH: string =
  'filemanagement/transmittalerrors/fix-transcode-endorsement-unmatched';
// OLDMIGRATEDFILEZXINT;
export const OLDMIGRATEDFILEZXINT_FIX_API_PATH: string =
  'filemanagement/transmittalerrors/fix-old-migrated-filezxint';
// CPLREVISEVOIDBADFLAG;
export const CPLREVISEVOIDBADFLAG_FIX_API_PATH: string =
  'filemanagement/transmittalerrors/fix-cpl-revise-void-badflag';
// Get legacy id
export const GET_TRANSMITTAL_ERRORS_LEGACY_ID_API_PATH: string =
  'filemanagement/transmittalerrors/get-legacyids';
// INCORRECTLEGACYID
export const INCORRECTLEGACYID_FIX_API_PATH: string =
  'filemanagement/transmittalerrors/fix-incorrect-legacyid';
// MISSINGLEGACYID
export const MISSINGLEGACYID_FIX_API_PATH: string =
  'filemanagement/transmittalerrors/fix-missing-legacyid';

// ZXINTMISSINGTRANCODEJACKET GET;
export const GET_TRANSMITTAL_ERRORS_TRANSCODES_API_PATH: string =
  'filemanagement/transmittalerrors/get-transcodes';
// ZXINTMISSINGTRANCODEJACKET FIX;
export const FIX_TRANSCODE_API_PATH: string =
  'filemanagement/transmittalerrors/fix-missing-trancode-onjacket';
// MISSINGCOUNTY GET
export const GET_TRANSMITTAL_ERRORS_COUNTY_CODE_API_PATH: string =
  'filemanagement/transmittalerrors/get-counties-singlelist';
// MISSINGCOUNTY FIX
export const FIX_COUNTY_API_PATH: string = 'filemanagement/transmittalerrors/fix-missing-county';
export const GET_FILE_MGMT_GENERAL_DATA_API_PATH: string = 'filemanagement/generalfiledata/search';
export const GET_FILE_MGMT_DOCUMENT_API_PATH: string =
  'filemanagement/generalfiledata/get-document';
export const GET_FILE_MGMT_DOCUMENTS_DATA_API_PATH: string =
  'filemanagement/get-documents-filemanagement';
export const GET_FILE_MGMT_FILE_STATUS_DATA_API_PATH: string =
  'type-tables/search?typeTableName=FileStatusType';
export const GENERAL_DATA_DOWNLOAD_API_PATH: string = 'filemanagement/generalfiledata/download';

// UW Request
export const UW_REQUEST_GRID_API_PATH: string = 'underwriter/search';
export const UW_REQUEST_DOWNLOAD_API_PATH: string = 'underwriter/download';
export const UW_CREATE_REQUEST_API_PATH: string = 'underwriter/create';
export const UW_DELETE_REQUEST_API_PATH: string = 'underwriter/{requestID}';
export const UW_REQUEST_API_PATH: string = 'underwriter/search';
export const UW_MORE_INFO_API_PATH: string = 'underwriter/searchRequestInfo';
export const GET_APPROVAL_STATES_API_PATH: string = 'underwriter/states';
export const GET_APPROVAL_LEVELS_API_PATH: string = 'underwriter/level/search';
export const GET_TYPES_API_PATH: string = 'type-tables/search?typeTableName=UWARequestType';
export const GET_SUB_TYPES_API_PATH: string = 'type-tables/search?typeTableName=UWARequestSubType';
export const GET_STATUS_API_PATH: string = 'type-tables/search?typeTableName=UWARequestStatusType';
export const GET_SUB_STATUS_API_PATH: string =
  'type-tables/search?typeTableName=UWARequestSubStatusType';
export const GET_PRIORITIES_API_PATH: string = 'type-tables/search?typeTableName=UWAPriorityType';
export const GET_UWA_PROPERTIES_API_PATH: string =
  'type-tables/search?typeTableName=UWAPropertyType';
export const GET_UWA_ACTIVITY_TYPES_API_PATH: string =
  'type-tables/search?typeTableName=UWAActivityType';
export const GET_UWA_ACTIVITY_STATUSES_API_PATH: string =
  'type-tables/search?typeTableName=UWAActivityStatusType';
export const GET_UWA_PRIORITIES_API_PATH: string =
  'type-tables/search?typeTableName=UWAPriorityType';
export const GET_GROUPS_BY_STATE_API_PATH: string = 'underwriter/states/{stateAbbr}';
export const GET_GROUPS_API_PATH: string = 'type-tables/search?typeTableName=UWAGroupType';
export const GET_UWA_APPROVAL_LEVEL_TYPES_API_PATH: string =
  'type-tables/search?typeTableName=UWAApprovalLevelType';
export const GET_UWA_USER_TYPES_API_PATH: string = 'type-tables/search?typeTableName=UWAUserType';
export const GET_AGENCY_TYPES_API_PATH: string = 'type-tables/search?typeTableName=UWAAgencyType';
export const GET_POLICY_TYPES_API_PATH: string = 'type-tables/search?typeTableName=UWAPolicyType';
export const GET_REQUESTID_API_PATH: string = 'underwriter/createBlank';
export const UW_ADD_POLICY_TYPE_API_PATH: string = 'underwriter/createPolicy';
export const UW_UPDATE_POLICY_TYPE_API_PATH: string = 'underwriter/updatePolicy';
export const UW_POLICY_TYPE_GRID_API_PATH: string = 'underwriter/searchPolicies';
export const UW_DELETE_POLICY_TYPE_API_PATH: string = 'underwriter/policy';
export const UW_OWNERS_GRID_API_PATH: string = 'underwriter/searchOwners';
export const UW_ATTACHMENTS_GRID_API_PATH: string = 'underwriter/attachment?requestID={requestId}';
export const UW_ATTACHMENT_API_PATH: string = 'underwriter/Attachment';
export const UW_ATTACHMENT_REMOVE_API_PATH: string =
  'underwriter/Attachment?requestID={requestId}&documentId={documentId}';
export const PROPERTIES_API_PATH: string = 'underwriter/property';
export const CONDITIONS_API_PATH: string = 'underwriter/activity';
export const GET_AUTHORITIES_OF_SELLER_API_PATH: string =
  'type-tables/search?typeTableName=UWAAuthorizationType';
export const GET_CONFLICTS_OF_INTEREST_API_PATH: string =
  'type-tables/search?typeTableName=UWAConflictOfInterestType';
export const GET_POLICY_WILL_BE_ISSUED_BY_API_PATH: string =
  'type-tables/search?typeTableName=UWAIssuedByType';
export const GET_APPROVAL_STATUS_API_PATH: string = 'lookup/underwriter-approvalStatus';
export const UW_APPROVALS_API_PATH: string = 'underwriter/approval';
export const UW_LATEST_POLICY_API_PATH: string = 'underwriter/LatestPolicyType';
export const GET_NOTE_TYPES_API_PATH: string = 'type-tables/search?typeTableName=UWANoteType';
export const NOTES_GRID_API_PATH: string = 'underwriter/note';
export const RELATED_REQUESTS_GRID_API_PATH: string = 'underwriter/relatedrequest';
export const RELATED_REQUESTS_BULK_API_PATH: string = 'underwriter/relatedrequest/bulk';
export const CONTACTS_API_PATH: string = 'underwriter/contact';
export const GET_MR_MS_TYPES_API_PATH: string = 'type-tables/search?typeTableName=PrefixType';
export const GET_SUFFIXES_API_PATH: string = 'type-tables/search?typeTableName=SuffixType';
export const GET_AGENCIS_DATA_API_PATH: string = 'underwriter/helpers/agency';
export const GET_RELATED_REQUESTS_GRID_DATA_API_PATH: string = 'underwriter/relatedrequest/search';

// Help Center
export const QUICK_REFERENCE_CARDS_API_PATH: string = 'helpcenter/quick-reference-cards';
export const QUICK_REFERENCE_REORDER_CARDS_API_PATH: string =
  'helpcenter/quick-reference-cards/reorder';
export const HELP_CENTER_FAQ_API_PATH: string = 'helpcenter/faqs';
export const HELP_CENTER_FAQ_REORDER_API_PATH: string = 'helpcenter/faqs/reorder';
export const HELP_CENTER_FAQ_UPDATE_API_PATH: string = 'helpcenter/faqs/update-set-list';
export const HELP_CENTER_GET_FILE_TYPE_API_PATH: string =
  'helpcenter/quick-reference-cards/file-type';
export const HELP_CENTER_GET_QRC_DISPLAY_PLACE: string =
  'helpcenter/quick-reference-cards/display-place';
export const HELP_CENTER_RELEASE_NOTE_API_PATH: string = 'helpcenter/release-notes';
export const HELP_CENTER_RELEASE_NOTE_REORDER_API_PATH: string = 'helpcenter/release-notes/reorder';

// Dashboard
export const DASHBOARD_BASIC_COUNTS_API_PATH: string = 'dashboard/basic-counts';
export const DASHBOARD_FILES_BY_STATE_API_PATH: string =
  'dashboard/file-summary/search?ByParam=ByState';
export const DASHBOARD_PRODUCT_SUMMARY_API_PATH: string = 'dashboard/product-summary/search';

// Policy upload

export const NEW_TAB_DATA: string = 'policyupload/get-admin-documentsupplemental-data/search';
