import React, { forwardRef, Ref, useImperativeHandle, useRef } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box } from '@mui/material';

import StewartCancelButton from '@stewart/common-ui/components/ActionButtons/StewartCancelButton';
import StewartSaveOrConfirmButton from '@stewart/common-ui/components/ActionButtons/StewartSaveOrConfirmButton';
import MaybeComponent from '@stewart/common-ui/components/MaybeComponent';
import {
  StewartConfirmDialogPropsType,
  StewartConfirmDialogRefType,
} from '@stewart/common-ui/dialogs/StewartConfirmDialog/models';
import StewartDialog from '@stewart/common-ui/dialogs/StewartDialog';
import { StewartDialogRefType } from '@stewart/common-ui/dialogs/StewartDialog/models';
import { label } from '@stewart/core/services';

// Component
const StewartConfirmDialog = (
  {
    onConfirm,
    onCancel,
    message,
    positiveActionTitle,
    negativeActionTitle,
    hideNegativeButton,
  }: StewartConfirmDialogPropsType,
  ref: Ref<StewartConfirmDialogRefType>
) => {
  const stewartDialogRef = useRef<StewartDialogRefType>(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      stewartDialogRef?.current?.open();
    },
    close: () => {
      stewartDialogRef?.current?.close();
    },
  }));

  function contentBlock(): ReactJSXElement {
    return <Box style={{ maxWidth: '28vw', overflowWrap: 'break-word' }}>{message}</Box>;
  }

  function actionsBlock(): ReactJSXElement {
    return (
      <Box>
        <MaybeComponent isVisible={!hideNegativeButton}>
          <StewartCancelButton
            sx={{ marginRight: '10px' }}
            onClick={() => onCancel()}
            negativeActionTitle={negativeActionTitle}
          />
        </MaybeComponent>

        <StewartSaveOrConfirmButton
          onClick={() => onConfirm()}
          positiveActionTitle={positiveActionTitle ?? label('lbl_confirm')}
        />
      </Box>
    );
  }
  return (
    <>
      <StewartDialog
        ref={stewartDialogRef}
        title={label('lbl_confirmation')}
        contentBlock={contentBlock}
        actionsBlock={actionsBlock}
        type="warning"
        messageState={message}
      />
    </>
  );
};

export default forwardRef<StewartConfirmDialogRefType, StewartConfirmDialogPropsType>(
  StewartConfirmDialog
);
