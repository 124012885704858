import React, {
  ChangeEvent,
  FormEvent,
  ForwardedRef,
  forwardRef,
  ReactElement,
  RefObject,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  IconButton,
  styled,
} from '@mui/material';
import { Formik, Form, FormikValues } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { keys } from 'lodash';

import {
  StewartDialogProps,
  StewartDialogRefType,
} from '@stewart/common-ui/dialogs/StewartDialog/models';
import ErrorIconToast from '@stewart/common-ui/icons/ErrorIconToast';
import SuccessIcon from '@stewart/common-ui/icons/SuccessIcon';
import WarningIcon from '@stewart/common-ui/icons/WarningIcon';
import { DialogModuleName, GridName } from '@stewart/common-ui/Data/enum';
import CloseIcon from '@mui/icons-material/Close'; // Add close icon for toast

import { colors, fontWeight, margin } from '@stewart/theme';
import { useStewartDialogStyles } from './style';

const StyledToastMessage = styled(Typography)({
  color: colors.black,
  fontSize: '0.875rem',
  alignContent: 'left,',
  margin: margin.xlarge,
  fontWeight: fontWeight.bold1,
  lineHeight: '24.2px',
});

const StewartDialog = (
  {
    title = '',
    contentBlock: ContentBlock,
    actionsBlock: ActionsBlock,
    validationSchema = null,
    initialValue = {},
    type = 'info',
    onSubmit = () => {},
    onCloseCallback = () => {},
    setIsTouched = null,
    onCloseConfirm = null,
    forceConfirmDialog,
    disableEscapeKeyDown = false,
    dialogStyle = {},
    dialogContentStyle = {},
    hideDoubleScrollbar = false,
    formStyle = {},
    paperClassName = '',
    valueMapperBeforeSubmit = (data: any) => data,
    headerColor = undefined,
    dialogModuleName = undefined,
    messageState,
    gridName = '',
  }: StewartDialogProps,
  ref: ForwardedRef<StewartDialogRefType>
) => {
  const classes = useStewartDialogStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const mainContentContainerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const closeDialog = () => {
    if (onCloseConfirm) {
      onCloseConfirm();
    } else {
      onCloseCallback?.();
      setIsOpen(false);
    }
  };

  const closeFromInheritDialog = (event?: any, reason?: string) => {
    if (reason && reason === 'backdropClick' && forceConfirmDialog) return;
    closeDialog();
  };

  const getDualScrollbarContainerClass = () => {
    let dualScrollbarContainerClass = classes.dualScrollbarContainer;
    if (dialogModuleName) {
      switch (dialogModuleName) {
        case DialogModuleName.ASSIGN_PERMISSION:
          dualScrollbarContainerClass = classes.assignPermission_dualScrollbarContainer;
          break;
        case DialogModuleName.EDIT_UNDERWRITER_CONFIGURATION:
          dualScrollbarContainerClass = classes.editUnderwriterConfiguration_dualScrollbarContainer;
          break;
        default:
          dualScrollbarContainerClass = classes.dualScrollbarContainer;
      }
    }
    return dualScrollbarContainerClass;
  };

  const getContentContainerClass = () => {
    let containerClass = classes.contentContainer;
    if (gridName) {
      switch (gridName) {
        case GridName.MANAGE_COMPANY_ACCESS:
          containerClass = classes.manageCompanyAccessContentContainer;
          break;
        case GridName.MANAGE_SIGNATURE:
          containerClass = classes.manageSignatureContentContainer;
          break;
        case GridName.MANAGE_USERS_COMPANY_ACCESS:
          containerClass = classes.manageUsersCompanyAccessContentContainer;
          break;
        default:
          containerClass = classes.contentContainer;
      }
    }
    return containerClass;
  };

  const doubleScrollbarStyle = !hideDoubleScrollbar ? getDualScrollbarContainerClass() : '';
  const contentContainerClass = getContentContainerClass();

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
    close: () => {
      closeFromInheritDialog();
    },
    closeWithoutValidation: () => {
      setIsOpen(false);
      onCloseCallback?.();
    },
    isOpened: () => {
      return isOpen;
    },
    element: mainContentContainerRef.current,
  }));

  const trimStringFields = (values: FormikValues, setValues: Function) => {
    const { fields } = validationSchema;
    const trimmedValues: FormikValues = {};

    keys(fields).forEach((fieldKey) => {
      let value = values[fieldKey];
      if (value && typeof value === 'string') {
        value = value.trim();
      }
      trimmedValues[fieldKey] = value;
    });
    setValues(trimmedValues);
  };

  function titleToRender(): ReactElement | string {
    switch (type) {
      case 'error':
        return <ErrorIconToast />;
      case 'success':
        return <SuccessIcon />;
      case 'warning':
        return <WarningIcon />;
      default:
        return <Typography className={classes.title}>{title}</Typography>;
    }
  }
  return (
    <Dialog
      onClose={closeFromInheritDialog}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      keepMounted={false}
      style={dialogStyle}
      PaperProps={{
        className: [
          classes.dialogPaper,
          paperClassName,
          hideDoubleScrollbar ? classes.staticDialogPaper : '',
          type === 'success' || type === 'error' ? classes.toastStyle : '',
        ].join(' '),
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {type === 'success' || type === 'error' ? (
        <Box sx={{ display: 'flex' }}>
          <Box className={type === 'success' ? classes.toastLeftSuccess : classes.toastLeftError}>
            {titleToRender()}
          </Box>
          <Box className={classes.innerToastContainer} sx={{ flexGrow: 1 }}>
            <StyledToastMessage>
              {type === 'error' ? messageState : title || titleToRender()}
            </StyledToastMessage>
            <IconButton size="small" onClick={closeDialog}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            style={headerColor ? { background: headerColor } : {}}
            className={`${classes.titleContainer} ${classes[type]}`}
          >
            <DialogTitle id="customized-dialog-title">{titleToRender()}</DialogTitle>
          </Box>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValue}
            onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
              onSubmit(valueMapperBeforeSubmit(values), formikHelpers);
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              setValues,
              getFieldProps,
              getFieldHelpers,
              setFieldValue,
              setFieldTouched,
              resetForm,
              errors,
            }) => {
              return (
                <Form style={formStyle}>
                  <DialogContent
                    className={`${contentContainerClass} ${doubleScrollbarStyle}`}
                    style={dialogContentStyle}
                    ref={mainContentContainerRef}
                  >
                    <ContentBlock
                      handleChange={(event: ChangeEvent<any>) => {
                        setIsTouched?.(true);
                        handleChange(event);
                      }}
                      handleSubmit={handleSubmit}
                      getFieldProps={getFieldProps}
                      getFieldHelpers={getFieldHelpers}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      values={values}
                      resetForm={resetForm}
                    />

                    {/* {ActionsBlock ? (
                  <DialogActions
                    className={hideDoubleScrollbar ? classes.customAction : classes.action}
                  >
                    <ActionsBlock
                      handleSubmit={(event: FormEvent<HTMLFormElement> | undefined) => {
                        trimStringFields(values, setValues);
                        handleSubmit(event);
                      }}
                      errors={errors}
                    />
                  </DialogActions>
                ) : null} */}
                  </DialogContent>

                  {ActionsBlock ? (
                    <DialogActions
                      className={hideDoubleScrollbar ? classes.customAction : classes.action}
                    >
                      <ActionsBlock
                        handleSubmit={(event: FormEvent<HTMLFormElement> | undefined) => {
                          trimStringFields(values, setValues);
                          handleSubmit(event);
                        }}
                        errors={errors}
                      />
                    </DialogActions>
                  ) : null}
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Dialog>
  );
};

export default forwardRef<StewartDialogRefType, StewartDialogProps>(StewartDialog);
