import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import StewartConfirmDialog from '@stewart/common-ui/dialogs/StewartConfirmDialog';
import { StewartConfirmDialogRefType } from '@stewart/common-ui/dialogs/StewartConfirmDialog/models';
import StewartDialog from '@stewart/common-ui/dialogs/StewartDialog';
import { StewartDialogRefType } from '@stewart/common-ui/dialogs/StewartDialog/models';
import {
  StewartDialogWithConfirmationChangesProps,
  StewartDialogWithConfirmRefType,
} from '@stewart/common-ui/dialogs/StewartDialogWithConfirmationChanges/models';
import { label, message } from '@stewart/core/services';

// Component
const StewartDialogWithConfirmationChanges = (
  {
    contentBlock,
    actionsBlock,
    title = '',
    validationSchema = null,
    initialValue = {},
    type = 'info',
    onSubmit = () => {},
    valueMapperBeforeSubmit = (data: any) => data,
    onCloseCallback = () => {},
    headerColor = undefined,
    dialogContentStyle = {},
    dialogModuleName = undefined,
  }: StewartDialogWithConfirmationChangesProps,
  ref: ForwardedRef<StewartDialogWithConfirmRefType>
) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const stewartDialogRef = useRef<StewartDialogRefType>(null);
  const confirmDialogRef = useRef<StewartConfirmDialogRefType>(null);

  const closeWithoutValidation = () => {
    stewartDialogRef?.current?.closeWithoutValidation();
    setIsTouched(false);
  };

  const onClose = (): void => {
    if (isTouched) {
      confirmDialogRef?.current?.open();
    } else {
      closeWithoutValidation();
    }
  };

  const onConfirm: Function = (): void => {
    confirmDialogRef?.current?.close();
    closeWithoutValidation();
  };

  const onCancel: Function = (): void => {
    confirmDialogRef?.current?.close();
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      stewartDialogRef?.current?.open();
    },
    closeWithoutValidation: () => {
      closeWithoutValidation();
    },
    close: () => {
      onClose();
    },
    setIsTouched: (isFormTouched: boolean) => {
      setIsTouched(isFormTouched);
    },
    getIsTouched: () => {
      return isTouched;
    },
    element: stewartDialogRef.current?.element,
  }));
  return (
    <>
      <StewartDialog
        contentBlock={contentBlock}
        actionsBlock={actionsBlock}
        title={title}
        validationSchema={validationSchema}
        initialValue={initialValue}
        type={type}
        onSubmit={onSubmit}
        valueMapperBeforeSubmit={valueMapperBeforeSubmit}
        ref={stewartDialogRef}
        setIsTouched={setIsTouched}
        onCloseConfirm={onClose}
        onCloseCallback={onCloseCallback}
        headerColor={headerColor}
        dialogContentStyle={dialogContentStyle}
        dialogModuleName={dialogModuleName}
      />

      <StewartConfirmDialog
        ref={confirmDialogRef}
        onConfirm={onConfirm}
        onCancel={onCancel}
        positiveActionTitle={label('lbl_discard')}
        message={message('msg_default_confirm_dialog_message')}
      />
    </>
  );
};

export default forwardRef<
  StewartDialogWithConfirmRefType,
  StewartDialogWithConfirmationChangesProps
>(StewartDialogWithConfirmationChanges);
