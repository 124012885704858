export const PERMISSIONS = {
  CACHE_ACCESS: 'CACHE_ACCESS',
  CACHE_ACCESS_REDIS: 'CACHE_ACCESS_REDIS',
  CACHE_ACCESS_METADATA: 'CACHE_ACCESS_METADATA',
  METADATACACHE_VIEW: 'METADATACACHE_VIEW',
  LOGS_ACCESS: 'LOGS_ACCESS',
  LOGS_ACCESS_EXTERNAL: 'LOGS_ACCESS_EXTERNAL',
  LOGS_ACCESS_INTERNAL: 'LOGS_ACCESS_INTERNAL',
  LOGS_ACCESS_INTEGRATION: 'LOGS_ACCESS_INTEGRATION',
  LOGS_ACCESS_CHANGESET: 'LOGS_ACCESS_CHANGESET',
  LOGS_ACCESS_CLOUD: 'LOGS_ACCESS_CLOUD',
  TYPE_TABLE_ACCESS: 'TYPE_TABLE_ACCESS',
  COMPANY_ACCESS: 'COMPANY_ACCESS',
  CONFIG_ADMIN_ACCESS: 'CONFIG_ADMIN_ACCESS',
  FILE_MANAGEMENT_ACCESS: 'FILES_ADMIN_ACCESS',
  FILES_ADMIN_SEARCH: 'FILES_ADMIN_SEARCH',
  FILES_ADMIN_VIEWDOCS: 'FILES_ADMIN_VIEWDOCS',
  // FILES_ADMIN_TRANSMITTAL_ERRORS: 'FILES_ADMIN_TRANSMITTAL_ERRORS',
  FILES_ADMIN_TRANS_ERR_RESOLVE: 'FILES_ADMIN_TRANS_ERR_RESOLVE',
  FILES_ADMIN_TRANS_ERR_VIEW: 'FILES_ADMIN_TRANS_ERR_VIEW',
  UWA_ACCESS: 'UWA_ACCESS',
  USERS_ADDMISSINGDEF_PERMS: 'USERS_ADDMISSINGDEF_PERMS',
  USERS_RESET_PERMISSIONS: 'USERS_RESET_PERMISSIONS',
  PERMISSIONS_ACCESS: 'PERMISSIONS_ACCESS',
  USERS_ACCESS: 'USERS_ACCESS',
  USERS_ADD: 'USERS_ADD',
  USERS_CLEAR_METADATA: 'USERS_CLEAR_METADATA',
  USERS_EXPORT: 'USERS_EXPORT',
  USERS_UPLOAD: 'USERS_UPLOAD',
  USERS_VIEW_DETAILS: 'USERS_VIEW_DETAILS',
  USERS_MODIFY_DETAILS: 'USERS_MODIFY_DETAILS',
  USERS_MODIFY_EXTERNAL_NAME: 'USERS_MODIFY_EXTERNAL_NAME',
  USERS_MODIFY_PERMISSIONS: 'USERS_MODIFY_PERMISSIONS',
  PERMISSIONS_MODIFYUSERS: 'PERMISSIONS_MODIFYUSERS',
  USERS_VIEW_TYPEANDPERMS: 'USERS_VIEW_TYPEANDPERMS',
  USERS_MODIFY_PROFILE: 'USERS_MODIFY_PROFILE',
  USERS_ENABLE: 'USERS_ENABLE',
  USERS_FIX: 'USERS_FIX',
  USERS_VIEW_COMPANY_ACCESS: 'USERS_VIEW_COMPANY_ACCESS',
  USERS_EDIT_COMPANY_ACCESS: 'USERS_EDIT_COMPANY_ACCESS',
  SIGNATURE_ACCESS: 'SIGNATURE_ACCESS',
  SIGNATURE_EXPORT: 'SIGNATURE_EXPORT',
  SIGNATURE_MODIFY_DETAILS: 'SIGNATURE_MODIFY_DETAILS',
  SIGNATURE_ENABLE: 'SIGNATURE_ENABLE',
  SIGNATURE_UPLOAD: 'SIGNATURE_UPLOAD',
  SIGNATURE_VIEW_IMAGE: 'SIGNATURE_VIEW_IMAGE',
  SIGNATURE_VIEW_SAMPLE: 'SIGNATURE_VIEW_SAMPLE',
  SIGNATURE_DELETE: 'SIGNATURE_DELETE',
  HELP_CENTER_ACCESS: 'HELP_CENTER_ACCESS',
  HELP_QRC_VIEW: 'HELP_QRC_VIEW',
  HELP_QRC_UPLOAD: 'HELP_QRC_UPLOAD',
  HELP_QRC_EDIT: 'HELP_QRC_EDIT',
  HELP_QRC_DELETE: 'HELP_QRC_DELETE',
  HELP_RELEASENOTES_VIEW: 'HELP_RELEASENOTES_VIEW',
  HELP_RELEASENOTES_UPLOAD: 'HELP_RELEASENOTES_UPLOAD',
  HELP_RELEASENOTES_EDIT: 'HELP_RELEASENOTES_EDIT',
  HELP_RELEASENOTES_DELETE: 'HELP_RELEASENOTES_DELETE',
  HELP_FAQ_VIEW: 'HELP_FAQ_VIEW',
  HELP_FAQ_UPLOAD: 'HELP_FAQ_UPLOAD',
  HELP_FAQ_EDIT: 'HELP_FAQ_EDIT',
  HELP_FAQ_DELETE: 'HELP_FAQ_DELETE',
  GEN_CPL_CANCELLATION: 'GEN_CPL_CANCELLATION',
  TYPE_TABLE_VIEWROW: 'TYPE_TABLE_VIEWROW',
  TYPE_TABLE_EDITROW: 'TYPE_TABLE_EDITROW',
  TYPE_TABLE_ENABLEROW: 'TYPE_TABLE_ENABLEROW',
  TYPE_TABLE_DELETEROW: 'TYPE_TABLE_DELETEROW',
  TYPE_TABLE_CREATEROW: 'TYPE_TABLE_CREATEROW',
  USERS_MODIFY_USERTYPE: 'USERS_MODIFY_USERTYPE',
  PERMISSIONS_VIEW: 'PERMISSIONS_VIEW',
  PERMISSIONS_EDIT: 'PERMISSIONS_EDIT',
  PERMISSIONS_DELETE: 'PERMISSIONS_DELETE',
  PERMISSIONS_VIEWUSERS: 'PERMISSIONS_VIEWUSERS',
  VIEWUSERTYPE_PERMSETS: 'VIEWUSERTYPE_PERMSETS',
  EDITUSERTYPE_PERMSETS: 'EDITUSERTYPE_PERMSETS',
  PERMISSIONS_CREATESET: 'PERMISSIONS_CREATESET',
  PERMISSIONS_CREATEITEM: 'PERMISSIONS_CREATEITEM',
  MODIFY_BRANCHES: 'MODIFY_BRANCHES',
  AUTOREPORT_CONFIG_CREATEROW: 'AUTOREPORT_CONFIG_CREATEROW',
  AUTOREPORT_CONFIG_VIEWROW: 'AUTOREPORT_CONFIG_VIEWROW',
  AUTOREPORT_CONFIG_EDITROW: 'AUTOREPORT_CONFIG_EDITROW',
  AUTOREPORT_CONFIG_ENABLEROW: 'AUTOREPORT_CONFIG_ENABLEROW',
  AUTOREPORT_CONFIG_DELETEROW: 'AUTOREPORT_CONFIG_DELETEROW',
  CONFIG_ACCESS_AUTOREPORT: 'CONFIG_ACCESS_AUTOREPORT',
  AUTOREPORT_CONFIG_ADDRMVDATA: 'AUTOREPORT_CONFIG_ADDRMVDATA',
  MODIFY_AUTOREPORT_CONFIG: 'MODIFY_AUTOREPORT_CONFIG',
  VIEW_AUTOREPORT_CONFIG: 'VIEW_AUTOREPORT_CONFIG',
  UPLOADS_ADMIN_ACCESS: 'UPLOADS_ADMIN_ACCESS',
  UPLOADS_POLICYIMAGES_DELETE: 'UPLOADS_POLICYIMAGES_DELETE',
  UPLOADS_POLICYIMAGES_MODIFY: 'UPLOADS_POLICYIMAGES_MODIFY',
  UPLOADS_POLICYIMAGES_REUPLOAD: 'UPLOADS_POLICYIMAGES_REUPLOAD',
  UPLOADS_POLICYIMAGES_SEARCH: 'UPLOADS_POLICYIMAGES_SEARCH',
  UPLOADS_POLICYIMAGES_VIEWDOCS: 'UPLOADS_POLICYIMAGES_VIEWDOCS',
  EXPORT_DATA_ADMIN: 'EXPORT_DATA_ADMIN',
  POLICYIMAGES_SEARCH: 'POLICYIMAGES_SEARCH',
  SITE_ACCESS: 'SITE_ACCESS',
  POLICY_ACCESS: 'POLICY_ACCESS',
};
